<script setup>
import { create, registerPlugin } from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import { onMounted, ref } from "vue";


const uniqueId = ref(`image_uploader_${Math.floor(Math.random() * 10000)}`);

const props = defineProps({
    id: {
        type: String,
        default: "image_uploader_" + Math.floor(Math.random() * 1000) + 1,
        required:false
    },
    labelIdle: {
        default:
            "<span class='text-center text-neutral-500 text-base font-semibold leading-normal'>Drag & drop or <span class='text-primary-500'>upload an image</span></span>",
        type: String,
    },
    hint: {
        type: String,
        required: false,
    },
    error: {
        type: String,
        required: false,
    },
    confirmation: {
        type: String,
        required: false,
    },
    allowMultipleFiles: {
        type: Boolean,
        default:false,
    },
    allowReorder: {
        type: Boolean,
        default:false,
    }
});

const pond = ref(null);

const emit = defineEmits(["update:modelValue"]);
registerPlugin(FilePondPluginFileValidateType, FilePondPluginImagePreview);
onMounted(() => {
   const fileInput = document.querySelector("#" + props.id);
   pond.value = create(fileInput, {
      storeAsFile: true,
      dropOnPage: true,
      credits: null,
      maxFiles:4,
      allowMultiple: props.allowMultipleFiles,
      labelIdle: props.labelIdle,
      allowReorder: props.allowReorder,
      onreorderfiles: (files, origin, target) => {
         emit(
            "update:modelValue",
            files.map(function (filepond) {
                  return filepond.file;
            }),
         );
      }
   });
    pond.value.on("updatefiles", (files) => {
        emit(
            "update:modelValue",
            files.map(function (filepond) {
                return filepond.file;
            }),
        );
    });
});

const clearFiles = () => {
    if (pond.value) {
        pond.value.removeFiles();
    }
};

function onSorted(file) { 
   console.log(file);
}

defineExpose({
    clearFiles
});
</script>
<template>
    <div>
        <div class="">
            <input :id="id" type="file" />
        </div>
        <div class="mt-[4px] text-xs font-normal leading-none text-danger-500">
            {{ props.error }}
        </div>
        <div class="mt-[4px] text-xs font-normal leading-none text-primary-500">
            {{ props.hint }}
        </div>
        <div class="mt-[4px] text-xs font-normal leading-none text-success-600">
            {{ props.confirmation }}
        </div>
    </div>
</template>
<style scoped></style>
